<template>
    <div id="zc-nav" style="position: fixed;top: 0; left: 0; right: 0;z-index: 2;background: #ffffff;">
        <b-navbar toggleable="lg" type="dark" variant="none">
            <b-navbar-toggle @click="HandleNavMenu" target="nav-collapse-header" aria-label="aria-label" left>
                <img src="@/assets/zaajira-candidate/assets/icons/menu.svg" width="30px" height="30px" class="img-fluid" />
            </b-navbar-toggle>
            <b-navbar-brand class="ismob-mr">
                <img @click="() => { $router.push({ path: '/candidate/home' }) }" style="cursor: pointer;"
                    src="@/assets/zaajira-candidate/assets/logo/zaajira-logo.svg" width="150"
                    class="is-desktop img-fluid" />


                <div v-if="!isLoggedIn" style="gap:.5rem;margin-right: 0.875rem; padding-right:.875rem"
                    class="is-mobile-d-flex d-flex justify-content-center align-items-center">
                    <a href="https://play.google.com/store/apps/details?id=com.zaajira" target="_blank"
                        rel="noopener noreferrer">
                        <img class="img-fluid app-icon" src="@/assets/google.png" alt="google-play" />
                    </a>
                    <a href="https://apps.apple.com/app/zaajira/id6449016993?platform=iphone" target="_blank"
                        rel="noopener noreferrer">
                        <img class="img-fluid app-icon" src="@/assets/apple.png" alt="google-play" />
                    </a>
                </div>
                <div v-else style="margin-right: 0.875rem;"
                    class="is-mobile-d-flex d-flex justify-content-center align-items-center">
                    <!-- <input type="text" class="form-control search-style">
                    <img style="cursor: pointer;" src="@/assets/zaajira-candidate/assets/icons/search-normal.svg"
                        width="19px" height="19px" class="img-fluid mr-3" /> -->

                    <div style="position: relative;">
                        <img v-if="!notificationData.length" ref="NotificationBtn" @click.prevent="gotoNotification"
                            src="@/assets/zaajira-candidate/assets/icons/Notification.svg"
                            style="max-width: 19px;cursor: pointer;" width="19px" height="19px" class="img-fluid" />
                        <img v-else ref="NotificationBtn" @click.prevent="gotoNotification"
                            src="@/assets/zaajira-candidate/assets/icons/New_Notification.svg"
                            style="max-width: 19px;cursor: pointer;" width="19px" height="19px" class="img-fluid" />

                        <NotifiationModal :isNotification="isNotification" :notificationData="notificationData" />


                    </div>

                    <b-nav-item-dropdown class="profile-dnd" right>
                        <!-- Using 'button-content' slot -->
                        <template #button-content>
                            <!-- <em>User</em> -->
                            <button id="profile-btn2" style="border: none; background:transparent;"
                                @click="closeNotification">
                                <b-avatar variant="info" class="" style="color: #fff;background-color: #e9ecef;">
                                    <img v-if="profileUrl !== null" :src="`${IMAGEURL}${profileUrl}`"
                                        style="width: 95%; height: auto; " />
                                    <img v-else src="@/assets/zaajira-candidate/assets/bg-images/avtar-image.png"
                                        class="img-fluid" />
                                </b-avatar>
                            </button>
                        </template>
                        <b-dropdown-item :to="{ path: '/candidate/user-profile' }">
                            {{ $gettext("Profile") }}
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ path: '/candidate/settings/change-password' }">
                            {{ $gettext("Change_password") }}
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ path: '/candidate/settings' }"> {{ $gettext("Settings") }}</b-dropdown-item>
                        <!-- <b-dropdown-item :to="{ path: 'home' }">Log Out</b-dropdown-item> -->
                        <b-dropdown-item @click="Logout"> {{ $gettext("Logout") }}</b-dropdown-item>
                    </b-nav-item-dropdown>
                </div>
            </b-navbar-brand>


            <!-- <div class="mb-3">
                <b-button v-b-toggle.my-collapse>Toggle Collapse</b-button>
                <b-button v-b-toggle.my-sidebar>Toggle Sidebar</b-button>
            </div> -->

            <b-collapse id="nav-collapse-header" is-nav>
                <b-navbar-nav v-if="isLoggedIn" style="gap:0px">
                    <!-- <b-nav-item to="/" style="position: relative; text-align: left !important;" class="text-center px-3">
                        {{ $gettext("Home") }}
                    </b-nav-item> -->
                    <b-nav-item style="text-align:left !important; padding-left: .875rem; position: relative;"
                        v-for="(  label, i  ) in   navLink  " :key="label.link" class="text-center"
                        :active-class="activeIndex === i ? 'nav-is-active' : ''" @click="activateBottomBar(i, label)">
                        {{ $gettext(label.link) }}
                        <div class="active-bottom-bar" v-if="activeIndex === i"></div>
                    </b-nav-item>
                    <b-nav-item class="is-mobile"
                        style="text-align:left !important; padding-left: .875rem; position: relative;" @click="Logout">
                        {{ $gettext("Logout") }}
                    </b-nav-item>
                    <b-nav-item-dropdown class="is-mobile"
                        style="text-align:left !important; padding-left: .875rem; position: relative;" right>
                        <template #button-content>
                            <i :class="langaue.value === 'en' ? 'flag flag-us' : 'flag flag-ke'"></i>
                            <span style="font-size:1rem;text-transform: uppercase;">{{ langaue.value }}</span>
                        </template>
                        <div>
                            <b-dropdown-item v-for="(  lang, i  ) in   langaueOptions  " :key="i"
                                @click="updateSelectedLanguage(lang)"> <i
                                    :class="lang.value === 'en' ? 'flag flag-us' : 'flag flag-ke'"></i>
                                <span
                                    style=" font-size:1rem;text-transform: uppercase; color: var(--neutral-dark-grey) !important;">
                                    {{ lang.value }}
                                </span>
                            </b-dropdown-item>
                        </div>
                    </b-nav-item-dropdown>

                </b-navbar-nav>
                <b-navbar-nav v-else style=" gap:0px; display: flex;" class="zc-pl-3 d-flex-None">
                    <!-- <b-nav-item style="position: relative;" v-for="(label, i) in beforeLoginNavLink" :key="label"
                        class="text-center" :active-class="activeIndex === i ? 'nav-is-active' : ''"
                        @click="activateBottomBar(i, label)">
                        {{ label.link }}
                        <div class="active-bottom-bar" v-if="activeIndex === i"></div>
                    </b-nav-item> -->
                    <b-nav-item to="/" style="position: relative; text-align: left !important;" class="text-center">
                        {{ $gettext("Home") }}
                    </b-nav-item>
                    <b-nav-item-dropdown :text='$gettext("CANDIDATE")' right style="margin-right: .87rem;">
                        <b-dropdown-item style="text-transform: uppercase;" :to="{ path: '/candidate/candidate-login' }">
                            <span style="color: var(--neutral-dark-grey) !important;">{{ $gettext("Login") }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item style="text-transform: uppercase;" :to="{ path: '/candidate/candidate-register' }">
                            <span style="color: var(--neutral-dark-grey) !important;">{{ $gettext("Register") }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item style="text-transform: uppercase;" :to="{ path: '/candidate/home' }">
                            <span style="color: var(--neutral-dark-grey) !important;">{{ $gettext("Find_Jobs") }}</span>
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown :text='$gettext("Employer")' right>
                        <b-dropdown-item style="text-transform: uppercase;" :to="{ path: '/employer-login' }">
                            <span style="color: var(--neutral-dark-grey) !important;">{{ $gettext("Login") }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item style="text-transform: uppercase;" :to="{ path: '/employer-register' }">
                            <span style="color: var(--neutral-dark-grey) !important;">
                                {{ $gettext("Register") }}
                            </span>
                        </b-dropdown-item>
                        <b-dropdown-item style="text-transform: uppercase;" :to="{ path: '/how-it-works' }">
                            <span style="color: var(--neutral-dark-grey) !important;">{{ $gettext("How_it_works") }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item style="text-transform: uppercase;" :to="{ path: '/how-it-works' }">
                            <span style="color: var(--neutral-dark-grey) !important;">{{ $gettext("Pricing") }}</span>
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown class="is-mobile" style="text-align:left !important; position: relative;" right>
                        <template #button-content>
                            <i :class="langaue.value === 'en' ? 'flag flag-us' : 'flag flag-ke'"></i>
                            <span style="font-size:1rem;text-transform: uppercase;">{{ langaue.value }}</span>
                        </template>
                        <div>
                            <b-dropdown-item v-for="(  lang, i  ) in   langaueOptions  " :key="i"
                                @click="updateSelectedLanguage(lang)"> <i
                                    :class="lang.value === 'en' ? 'flag flag-us' : 'flag flag-ke'"></i>
                                <span
                                    style=" font-size:1rem;text-transform: uppercase; color: var(--neutral-dark-grey) !important;">
                                    {{ lang.value }}
                                </span>
                            </b-dropdown-item>
                        </div>
                    </b-nav-item-dropdown>
                    <!-- <CustomDropdown :selectedOption="langaue" :options="langaueOptions" label="label"
                        @update:selectedOption="updateSelectedLanguage"></CustomDropdown> -->
                </b-navbar-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto is-desktop">

                    <div v-if="!isLoggedIn" style="gap:.87rem;" class="d-flex justify-content-center align-items-center">
                        <b-nav-item-dropdown :text='$gettext("CANDIDATE")' right>
                            <!-- <b-dropdown-item style="text-transform: uppercase;" :to="{ path: '/' }">
                                <span style="color: var(--neutral-dark-grey) !important;">{{ $gettext("Home") }}</span>
                            </b-dropdown-item> -->
                            <b-dropdown-item style="text-transform: uppercase;"
                                :to="{ path: '/candidate/candidate-login' }">
                                <span style="color: var(--neutral-dark-grey) !important;">{{ $gettext("Login") }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item style="text-transform: uppercase;"
                                :to="{ path: '/candidate/candidate-register' }">
                                <span style="color: var(--neutral-dark-grey) !important;">{{ $gettext("Register") }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item style="text-transform: uppercase;" :to="{ path: '/candidate/home' }">
                                <span style="color: var(--neutral-dark-grey) !important;">{{ $gettext("Find_Jobs") }}</span>
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown :text='$gettext("Employer")' right>
                            <b-dropdown-item style="text-transform: uppercase;" :to="{ path: '/employer-login' }">
                                <span style="color: var(--neutral-dark-grey) !important;">{{ $gettext("Login") }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item style="text-transform: uppercase;" :to="{ path: '/employer-register' }">
                                <span style="color: var(--neutral-dark-grey) !important;">
                                    {{ $gettext("Register") }}
                                </span>
                            </b-dropdown-item>
                            <b-dropdown-item style="text-transform: uppercase;" :to="{ path: '/how-it-works' }">
                                <span style="color: var(--neutral-dark-grey) !important;">{{ $gettext("How_it_works")
                                }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item style="text-transform: uppercase;" :to="{ path: '/how-it-works' }">
                                <span style="color: var(--neutral-dark-grey) !important;">{{ $gettext("Pricing") }} </span>
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                        <a href="https://play.google.com/store/apps/details?id=com.zaajira" target="_blank"
                            rel="noopener noreferrer">
                            <img class="img-fluid app-icon" src="@/assets/google.png" alt="google-play" />
                        </a>
                        <a href="https://apps.apple.com/app/zaajira/id6449016993?platform=iphone" target="_blank"
                            rel="noopener noreferrer">
                            <img class="img-fluid app-icon" src="@/assets/apple.png" alt="app-store" />
                        </a>

                        <b-nav-item-dropdown right>
                            <template #button-content>
                                <i :class="langaue.value === 'en' ? 'flag flag-us' : 'flag flag-ke'"></i>
                                <span style="font-size:1rem;text-transform: uppercase;">{{ langaue.value }}</span>
                            </template>
                            <div>
                                <b-dropdown-item v-for="(  lang, i  ) in   langaueOptions  " :key="i"
                                    @click="updateSelectedLanguage(lang)"> <i
                                        :class="lang.value === 'en' ? 'flag flag-us' : 'flag flag-ke'"></i>
                                    <span
                                        style="font-size:1rem;text-transform: uppercase; color: var(--neutral-dark-grey) !important;">
                                        {{ lang.value }}
                                    </span>
                                </b-dropdown-item>
                            </div>
                        </b-nav-item-dropdown>
                    </div>
                    <div v-else style="gap:11px" class="d-flex justify-content-center align-items-center">
                        <!-- <input type="text" class="form-control" />
                        <img style="cursor: pointer;" src="@/assets/zaajira-candidate/assets/icons/search-normal.svg"
                            width="19px" height="19px" class="img-fluid" /> -->
                        <!-- <span style="border: 1px solid #626262;width: 1px;height: 23px;"></span> -->
                        <!-- language selector @start -->
                        <div style="position: relative;">
                            <img v-if="!notificationData.length" ref="NotificationBtn" @click.prevent="gotoNotification"
                                src="@/assets/zaajira-candidate/assets/icons/Notification.svg"
                                style="max-width: 19px;cursor: pointer;" width="19px" height="19px" class="img-fluid" />
                            <img v-else ref="NotificationBtn" @click.prevent="gotoNotification"
                                src="@/assets/zaajira-candidate/assets/icons/New_Notification.svg"
                                style="max-width: 19px;cursor: pointer;" width="19px" height="19px" class="img-fluid" />
                            <NotifiationModal :isNotification="isNotification" :notificationData="notificationData" />
                        </div>
                        <span class="text-nowrap">
                            <p class="h-label m-0">
                                {{ fullName }}
                            </p>
                            <p class="h-title m-0">{{ designation }}</p>
                        </span>

                        <b-nav-item-dropdown class="profile-dnd" right>
                            <!-- Using 'button-content' slot -->
                            <template #button-content>
                                <!-- <em>User</em> -->
                                <button id="profile-btn1" style="border: none; background:transparent;"
                                    @click="closeNotification">
                                    <b-avatar variant="info" class="" style="color: #fff;background-color: #e9ecef;">
                                        <img v-if="profileUrl !== null" :src="`${IMAGEURL}${profileUrl}`"
                                            style="width: 95%; height: auto; " />
                                        <img v-else src="@/assets/zaajira-candidate/assets/bg-images/avtar-image.png"
                                            class="img-fluid" />
                                    </b-avatar>
                                </button>
                            </template>
                            <b-dropdown-item :to="{ path: '/candidate/user-profile' }">{{ $gettext("Profile")
                            }}</b-dropdown-item>
                            <b-dropdown-item :to="{ path: '/candidate/settings/change-password' }">
                                {{ $gettext("Change_password") }}
                            </b-dropdown-item>
                            <b-dropdown-item :to="{ path: '/candidate/settings' }">{{ $gettext("Settings")
                            }}</b-dropdown-item>
                            <b-dropdown-item @click="Logout">{{ $gettext("Logout") }}</b-dropdown-item>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown right>
                            <template #button-content>
                                <i :class="langaue.value === 'en' ? 'flag flag-us' : 'flag flag-ke'"></i>
                                <span style="font-size:1rem;text-transform: uppercase;">{{ langaue.value }}</span>
                            </template>
                            <div>
                                <b-dropdown-item v-for="(  lang, i  ) in   langaueOptions  " :key="i"
                                    @click="updateSelectedLanguage(lang)"> <i
                                        :class="lang.value === 'en' ? 'flag flag-us' : 'flag flag-ke'"></i>
                                    <span
                                        style="font-size:1rem;text-transform: uppercase; color: var(--neutral-dark-grey) !important;">
                                        {{ lang.value }}
                                    </span>
                                </b-dropdown-item>
                            </div>
                            <!-- language selector @end -->
                        </b-nav-item-dropdown>
                    </div>
                </b-navbar-nav>
                <!-- <b-navbar-nav class="ml-auto">
                    <b-nav-form>
                        <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
                        <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
                    </b-nav-form>

                    <b-nav-item-dropdown text="Lang" right>
                        <b-dropdown-item :to="{ path: 'home' }">EN</b-dropdown-item>
                        <b-dropdown-item :to="{ path: 'home' }">ES</b-dropdown-item>
                        <b-dropdown-item :to="{ path: 'home' }">RU</b-dropdown-item>
                        <b-dropdown-item :to="{ path: 'home' }">FA</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav> -->
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import NotifiationModal from '../Modal/NotifiationModal.vue'
import amazonIcon from "@/assets/zaajira-candidate/assets/company-logo/amazon.svg"
import CandidateService from "@/apiServices/CandidateService";
import CustomDropdown from '@/components/Dropdown/Dropdown.vue'
import avtar from "@/assets/zaajira-candidate/assets/icons/ph_user-bold.svg";
import { IMAGEURL } from "@/config";

export default {
    name: "CandidateHeader",
    components: { NotifiationModal, CustomDropdown },
    // props: {
    //     isLoggedIn: {
    //         type: Boolean,
    //         default: false
    //     }
    // },
    data() {
        return {
            isMobileMenu: window.innerWidth <= 992,
            avtar,
            IMAGEURL,
            profileUrl: this.$store.getters.getUserProfile,
            fullName: null,
            designation: null,
            // Find Jobs Download Mobile App Register  Login 
            isLoggedIn: this.$store.getters.getLoggedUser.isLoggedIn,
            beforeLoginNavLink: [
                { link: 'Find_Jobs', path: '/candidate/home', },
                { link: 'Download_mobile_app', path: '/download-zaajira-app', },
                { link: 'Register', path: '/candidate/candidate-register', },
                { link: 'Login', path: '/candidate/candidate-login', },
            ],
            navLink: [
                { link: 'Find_Jobs', path: '/candidate/home', },
                { link: 'Saved_Jobs', path: '/candidate/save-job', },
                { link: 'Profile', path: '/candidate/user-profile', },
                { link: 'Chat', path: '/candidate/chat', },
                { link: 'Settings', path: '/candidate/settings', },
                // { link: 'Download Mobile App ', path: '/download-zaajira-app', },
                // { link: 'Register', path: '/candidate/candidate-register', },
                // { link: 'Login', path: '/candidate/candidate-login', },
            ],
            activeIndex: null,
            navlinkIsActive: false,
            ProfileBtn: false,
            isNotification: false,
            notificationData: [
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },
                // {
                //     avatar: amazonIcon,
                //     message: "New Message",
                //     details: "Apple replied to your message",
                //     timeAgo: "2 m ago"
                // },

            ],
            langaue: { "en": "English", label: "English", value: 'en' },
            langaueOptions: [{ "en": "English", label: "English", value: 'en' }, { "sw": "Swahili", label: "Swahili", value: 'sw' }],
            toggleHeaderMenu: false,
        }
    },
    watch: {
        '$store.getters.getLoggedUser.isLoggedIn'(newValue) {
            this.isLoggedIn = newValue;
        },
    },
    methods: {
        // onMouseoverDropdown() {
        //     this.$root.$on('bv::dropdown::show', bvEvent => {
        //         console.log('Dropdown is about to be shown', bvEvent)
        //     })
        // },
        downloadApp(path) {
            this.$router.push({ path }).then(() => {
                window.open(`${path}`, '_blank');
            });

        },
        HandleNavMenu(e) {
            this.toggleHeaderMenu = !this.toggleHeaderMenu
            const nav = document.getElementById('nav-collapse-header')
            if (this.toggleHeaderMenu) {
                document.addEventListener('click', this.handleHeaderClickOutside);

            }
            else {

                document.removeEventListener('click', this.handleHeaderClickOutside);
            }
        },
        handleHeaderClickOutside(event) {
            // console.log(event.target);
            // console.log(this.toggleHeaderMenu);
            // console.log(this.toggleHeaderMenu && !this.$el.contains(event.target));
            if (this.toggleHeaderMenu && !this.$el.contains(event.target)) {
                // this.$root.$emit('bv::toggle::collapse', 'nav-collapse-header')
                this.$root.$emit('bv::toggle::collapse', 'nav-collapse-header')
                this.toggleHeaderMenu = false
                document.removeEventListener('click', this.handleHeaderClickOutside);
            }
        },
        // showMenu: function () {
        //     this.toggleHeaderMenu = true
        //     document.querySelector(".navbar-collapse").classList.add("show");
        // },
        // hideMenu: function () {
        //     // this.toggleHeaderMenu = false
        //     document.querySelector(".navbar-collapse").classList.remove("show");

        // },

        updateSelectedLanguage(language) {
            this.langaue = language;
            console.log(language);
            this.$language.current = language.value;
            this.$store.dispatch("onSetSelectedLang", language.value);

        },
        PreFilledData() {
            let loader = this.$loading.show();
            // console.log("this.$store.getters.getLoggedUser", this.$store.getters.getLoggedUser);
            const userData = this.$store.getters.getLoggedUser
            console.log('userData', userData);
            CandidateService.getUserByUserID(userData.userId)
                .then(async (res) => {
                    console.log("res>>>", res.data.data[0])
                    this.profileUrl = res.data.data[0].profile_image
                    this.fullName = res.data.data[0].user_username
                    this.designation = res.data.data[0].designation
                    this.$store.dispatch("onSetProfileImage", res.data.data[0].profile_image);
                })
            loader.hide();
        },
        // Logout() {
        //     const payload = {
        //         // userRole: this.userRole,
        //         // usertype: this.usertype,
        //         // email: this.email,
        //         // phone: this.phone,
        //         userRole: 3,
        //         isLoggedIn: false
        //     }
        //     if (payload.userRole == 3) {
        //         this.$store.dispatch("onSetUserSigninValues", payload);
        //         this.$router.push({ path: '/' })
        //         // this.$router.push({ name: "Home-Page" })
        //     }
        // },
        Logout() {
            const payload = {};
            localStorage.removeItem("currentLang");
            this.$language.current = "en"
            this.$store.dispatch("onLogout");
        },
        gotoNotification(event) {
            this.isNotification = !this.isNotification;
            event.stopPropagation();
            if (this.isNotification) {
                // for hide profile avtar dropdown
                document.querySelectorAll(".show").forEach(function (element) {
                    element.classList.remove("show");
                });

                document.addEventListener('click', this.closeNotification);
            } else {
                document.removeEventListener('click', this.closeNotification);
            }
        },
        closeNotification(event) {
            // if (this.toggleHeaderMenu) {
            //     this.$root.$emit('bv::toggle::collapse', 'nav-collapse-header')
            // }

            if (this.isNotification) {
                console.log("event", event);
                const notification = document.getElementById('zc-notification').id
                console.log("parentNode", notification);
                if (notification !== 'zc-notification') {
                    this.isNotification = true;
                }
                else {
                    this.isNotification = false;
                    document.removeEventListener('click', this.closeNotification);
                }
            }
            else {
                return
            }

        },
        activateBottomBar(index, label) {
            this.navlinkIsActive = !this.navlinkIsActive
            this.activeIndex = index;
            console.log("index", index);
            this.$router.push({ path: label.path })
        },

    },
    beforeMount() {
        this.isLoggedIn = this.$store.getters.getLoggedUser.isLoggedIn
        this.PreFilledData()
        const CurrentLang = JSON.parse(localStorage.getItem('currentLang'))
        console.log("lang>>>>>", CurrentLang);
        this.langaue = CurrentLang !== null && CurrentLang !== undefined ? CurrentLang : { "en": "English", label: "English", value: 'en' };
        this.$language.current = CurrentLang !== null && CurrentLang !== undefined ? CurrentLang?.value : 'en';
    },
    computed: {
        profileUrl1() {
            // console.log('aman sir', this.$store.state.CandidateStorePersist.signinForm.profileUrl);
            return this.$store.state.CandidateStorePersist.signinForm.profileUrl
        }
    },
    watch: {
        $router(to, from) {
            // console.log("to", to);
            // console.log("from", from);
            // Use the route path to find and set the activeIndex
            this.activeIndex = this.navLink.findIndex(link => link.path === to.path);
        },
        profileUrl1(newValue) {
            // console.log('newValue', newValue);
            // console.log('filewwwww', this.profileUrl);
            this.profileUrl = newValue
            // console.log('filewwwww', this.profileUrl);
        }
    },

    mounted() {
        // window.addEventListener("click", this.handleHeaderClickOutside);
    },
    beforeUnmount() {
        // window.removeEventListener("click", this.handleHeaderClickOutside);
    },


}
</script>

<style>
.navbar {
    font-family: mp-bold;
    text-transform: Uppercase;
    font-size: 16px;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 18%) !important;
}

.nav-link {
    color: var(--neutral-dark-grey, #555556) !important;
}



/* .ismob-mr {
    margin-right: 100px;
} */

.nav-is-active {
    color: rgb(243, 111, 33) !important;
}

.active-bottom-bar {
    width: 59px;
    height: 4px;
    background: rgb(243, 111, 33);
    border-top-left-radius: 10px;
    position: relative;
    top: 23px;
    left: 0;
    border-top-right-radius: 10px;
}

.triangle-top {
    position: absolute;
    top: 19px;
    left: 0;
    width: 0;
    height: 0;
    margin: 18px auto;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 20px solid #e0e0e0;
    transform: rotate(271deg);
}

.profile-dnd ul li a {
    font-size: .875rem;
    text-transform: uppercase !important;

}

.app-icon {
    width: 7.5rem;
    cursor: pointer;
}

.d-flex-None {
    display: none !important;
}



@media screen and (min-width: 993px) {
    .b-nav-dropdown:hover .dropdown-menu {
        display: block;
    }
}

@media screen and (max-width:992px) {
    .d-flex-None {
        display: flex !important;
    }

    .ismob-mr {
        margin-right: -32px !important;
    }

    .zc-pl-3 {
        padding-left: 1rem !important;
    }

    .active-bottom-bar {
        display: none;
    }

    .search-style {
        max-width: 12rem;
        margin-right: 11px;
    }
}

.dropdown a:hover {
    background-color: transparent !important;
}

@media screen and (max-width:425px) {
    .app-icon {
        width: 7rem;
    }

    .search-style {
        width: 6rem;
        margin-right: 11px;
    }
}
</style>