<template>
    <div class="Field" ref="dropdownRef">
        <span @click="toggleDnD" style="width: 100%;height: 1.5rem;font-weight: 600;color: rgb(0, 0, 0);">{{ label !== null
            ? selectedOption.label :
            displayText
        }}</span>
        <span @click="toggleDnD"> <i class="fa fa-angle-down" aria-hidden="true"></i></span>
        <div v-if="dropdownOption" id="zc-dnd" class="dnd-option">
            <div v-for="(op, i) in options" :key="i" @click="selectLang(op)" class="dnd-option-item">
                <span v-if="label !== null">{{ op.label }}</span>
                <span v-else>{{ op }}</span>
                <b-form-radio class="" v-model="selectedOption" :value="op"></b-form-radio>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'CustomDropdown',
    props: {
        selectedOption: [String, Object, Array], // v-model value
        options: Array, // Dropdown options
        label: { type: String, default: null }
    },

    data() {
        return {
            dropdownOption: false
        };
    },

    computed: {
        displayText() {

            return this.selectedOption !== '' ? this.selectedOption : 'Select Language';

        }
    },

    mounted() {
        document.addEventListener('click', this.closeDropdown);
    },

    beforeDestroy() {
        document.removeEventListener('click', this.closeDropdown);
    },

    methods: {
        toggleDnD() {
            this.dropdownOption = !this.dropdownOption;
        },

        selectLang(selectedLang) {
            this.$emit('update:selectedOption', selectedLang);
            this.dropdownOption = false;
        },

        closeDropdown(event) {
            const dropdown = this.$refs.dropdownRef;
            if (!dropdown.contains(event.target)) {
                this.dropdownOption = false;
            }
        }
    }
};
</script>
  
<style>
.Field {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    background: #EFEFEF;
    /* border: 1px #A4A4A4 solid; */
    justify-content: space-between;
    align-items: center;
    display: inline-flex;
}


.dnd-option {
    position: absolute;
    top: 52px;
    left: 0;
    width: 100%;
    height: fit-content;
    background: #F9F9F9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    border: 1px #E0E0E0 solid;
    z-index: 1000;
    padding: 10px;
}

.dnd-option-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: .875rem .125rem;
}

.dnd-option-item:hover {
    background: var(--ai-pplicant-logo);
    padding-inline: 11px;
    margin: .875rem -10px;
}

/* @media screen and (max-width:393px) {
    .Field {
        width: 89%;
    }
} */
</style>
  