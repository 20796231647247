<template>
    <div v-if="isNotification" class="zc-notify-container">
        <div v-if="isNotification && notificationData.length" id="zc-notification" class="zc-container"
            style="padding: 1rem; text-align: start;">
            <div v-for="(item, index) in notificationData" :key="index" class="notification-item">
                <b-row class="py-3" style="border-bottom: 1px solid #F5F5F7;">
                    <b-col cols="9">
                        <div class="d-flex">
                            <b-avatar style="background: #F1F1F1;" class="mr-2">
                                <img :src="item.avatar" width="75%" height="auto" class="img-fluid" />
                            </b-avatar>
                            <div style="padding: 0 0 0 0; margin-top: -4px;" cols="8">
                                <span style="font-size:1rem;" class="h-job-name">{{ item.message }}</span>
                                <div style="gap: 10px;" class="d-flex">
                                    <span
                                        style="font-size: 0.875rem; font-weight:500; color: var(--neutral-dark-grey, #9C9CA4);"
                                        class="h-company">
                                        {{ item.details }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="3">
                        <div class="d-flex flex-column align-items-end justify-content-between">
                            <div class="chat-time" style="color: var(--neutral-dark-grey, #9C9CA4);">{{ item.timeAgo }}
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                <circle cx="4" cy="4" r="4" fill="#F90" />
                            </svg>
                        </div>
                    </b-col>
                </b-row>
                <!-- <div class="d-flex justify-content-between py-3" style="border-bottom: 1px solid #F5F5F7;">
                <div class="d-flex">
                    <b-avatar style="background: #F1F1F1;" class="mr-2">
                        <img :src="item.avatar" width="75%" height="auto" class="img-fluid" />
                    </b-avatar>
                    <div style="padding: 0 0 0 0; margin-top: -4px;" cols="8">
                        <span style="font-size:1rem;" class="h-job-name">{{ item.message }}</span>
                        <div style="gap: 10px;" class="d-flex">
                            <span style="font-size: 0.875rem; font-weight:500; color: var(--neutral-dark-grey, #9C9CA4);"
                                class="h-company">
                                {{ item.details }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column align-items-end justify-content-between">
                    <div class="chat-time" style="color: var(--neutral-dark-grey, #9C9CA4);">{{ item.timeAgo }}</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                        <circle cx="4" cy="4" r="4" fill="#F90" />
                    </svg>
                </div>
            </div> -->
            </div>
        </div>
        <div v-else-if="isNotification" id="zc-notification" class="zc-container" style="padding: 1rem; text-align: start;">
            <h2 style="font-size: clamp(1rem, 4vw, 1rem);color: #000;text-align: center;">There are no notifications</h2>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        isNotification: {
            type: Boolean,
            default: true
        },

        notificationData: {
            type: Array,
            default: () => []
        }
    },

};
</script>  
<style>
.zc-notify-container {
    position: fixed;
    background: #00000042;
    left: 0;
    right: 0;
    bottom: 0;
    top: 75px;
    transition: background-color 0.3s ease-in-out;
}

#zc-notification {
    position: absolute;
    top: -3px;
    right: 4px;
    text-transform: math-auto;
    z-index: 1;
    width: clamp(18rem, 5vw, 45rem);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 62vh;
    overflow: auto;
    padding: 1rem;
    text-align: start;
    box-shadow: 0px 50px 100px 0px rgba(0, 0, 0, 0.10);
}
</style>
  


<!-- <NotificationComponent :isNotification="receivedIsNotification" :notificationData="receivedNotificationData" /> -->